import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ApplicationRef, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { UpdatePwdComponent } from '../update-pwd/update-pwd.component';
import { environment } from '../../environments/environment';
import {
  NguCarousel,
  NguCarouselDefDirective,
  NguCarouselNextDirective,
  NguCarouselPrevDirective,
  NguItemComponent
} from '@ngu/carousel';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { Md5 } from 'ts-md5';
import { CommonService } from '../../shared/common.service';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    CommonModule, SharedModule, RouterModule,
    NguCarousel,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguItemComponent,UpdatePwdComponent
  ],
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  env = environment;
  public loginform: FormGroup;
  public form_validation = "";
  public action_type = 1;
  public viewFilesINpData: any = {};
  public showUpdatePopup=0;
  public caursolList: Array<any> = [
    { id: 1, image: './assets/images/accounts.svg', name: 'SMATO ACCOUNTS', name1: 'Admin Menu', name2: 'Access Rights', name3: 'Reports', name4: 'Data Import', icon1: './assets/images/admin-menu.svg', icon2: './assets/images/access-rights.svg', icon4: './assets/images/data-import.svg', icon3: './assets/images/reports.svg' },
    { id: 2, image: './assets/images/books.svg', name: 'Books', name1: 'Orders', name2: 'Invoice', name3: 'Estimates', name4: 'Payments', icon1: './assets/images/orders.svg', icon2: './assets/images/invoice.svg', icon3: './assets/images/estimates.svg', icon4: './assets/images/payments.svg' },
    { id: 3, image: './assets/images/crm.svg', name: 'CRM', name1: 'Leads', name2: 'Customers', name3: 'Vendors', name4: 'Items', icon1: './assets/images/leads.svg', icon2: './assets/images/customer.svg', icon3: './assets/images/item.svg', icon4: './assets/images/vendor.svg' },
    { id: 4, image: './assets/images/project-managment.svg', name: 'HR', name1: 'Employee', name2: 'Travel', name3: 'Payroll', name4: 'Leaves', icon1: './assets/images/employees.svg', icon2: './assets/images/travel.svg', icon3: './assets/images/payroll.svg', icon4: './assets/images/leave.svg' }];
  public carouselBanner = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, all: 0 },
    slide: 2,
    speed: 800,
    interval: {
      timing: 10000,
      initialDelay: 500
    },
    point: {
      visible: true
    },
    load: 1,
    loop: false,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  public inintialValue: any = {};
  public updateMessg:any={};
  public userDetail: any;
  constructor(
    private appref: ApplicationRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    public cs: CommonService,
    public fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) {
    this.loginform = this.fb.group({
      user_id: ['', Validators.required],
      user_pwd: ['', [Validators.required]],
      // old_pwd:[,[this.cs.updatePasswordPattern]]
    });
    this.inintialValue = this.loginform.value;
    // this.loginform.patchValue({old_pwd:this.loginform.value.user_pwd});
  }
  ngOnInit() {
  }
  changeform(type: any) {
    this.action_type = type;
  }
  public formsubmit() {
    if (this.action_type == 1) {
      this.loginSubmit();
    } else {
      this.forgotPwd();
    }
  }
  public forgotPwd() {
    if (this.loginform.valid) {
      this.form_validation = "";
      this.cs
        .postData({
          sourceid: "user/forgotpwd",
          info: {
            user_id: this.loginform.value.user_id,
            token: this.loginform.value.token
          },
        })
        .subscribe((data: any) => {
          if (data.status == 1) {
            this.form_validation = "Your password and further instructions have been sent to your e-mail address";
          } else if (data.status == 2) {
          }
        }
        )
    } else {
    }
  }
  close(data:any){
    if(data.type==1){
      this.showUpdatePopup=0;
    }
  }
  public loginSubmit(): void {
    this.form_validation = "";
    if (this.loginform.valid) {
      this.form_validation = "";
      const md5 = new Md5();
      this.cs
        .postData({
          sourceid: "account/login",
          info: {
            user_id: this.loginform.value.user_id,
            user_pwd: md5.appendStr(this.loginform.value.user_pwd).end(),
            entity_id: this.loginform.value.entity_id,
            platform: 1
          },
        })
        .subscribe(
          async (data: any) => {
            if (data["status"] == 1) {

              if (data["data"]['pwd_change_alert']==0 && (this.loginform.value.user_pwd.match(this.cs.updatePasswordPattern))){
                try {
                  this.cs.userSession = { ...data["data"], ...{ akey: data['akey'] } };
                  if (isPlatformBrowser(this.platformId)) {
                    localStorage.setItem(
                      "smarte2e_smato",
                      JSON.stringify(this.cs.userSession)
                    );
                  }
                  this.cs.userIdle.startWatching();
                  // Start watching when user idle is starting.
                  this.cs.userIdle.onTimerStart().subscribe((count: any) =>{
                  }
                 );
                  // Start watch when time is up.
                  this.cs.userIdle.onTimeout().subscribe(() => {
                    this.cs.userIdle.stopTimer();
                    this.cs.logout();
                  });
                    this.router.navigate(["/dash"]);
                } catch (error) {
                  this.cs.userSession['account_img'] = '';
                  if (isPlatformBrowser(this.platformId)) {
                    localStorage.setItem(
                      "smarte2e_smato",
                      JSON.stringify(this.cs.userSession)
                    );
                  }
                  this.router.navigate(["/dash"]);
                }
              }
              else if ((data["data"]['pwd_change_alert']==0 && !(this.loginform.value.user_pwd.match(this.cs.updatePasswordPattern))) || (data["data"]['pwd_change_alert']))
              {
                this.userDetail = {user_session:{ ...data["data"], ...{ akey: data['akey'] }},mesg:0};
                this.updateMessg={Message:data["data"]['pwd_change_alert'],Id:this.userDetail.user_session.account_id}
                this.showUpdatePopup=1;
              }
              // if (isPlatformBrowser(this.platformId)) {
              //   this.cs
              //     .postData({
              //       sourceid: "listcalldbproc",
              //       info: {
              //         db: "cms",
              //         procname: "account_details",
              //         vals: [
              //           this.cs.userSession.user_id, this.cs.userSession.account_type
              //         ],
              //       },
              //     })
              //     .subscribe((data: any) => {
              //       this.cs.userSession = { ... this.cs.userSession, ...data['data'][0][0] };
              //       localStorage.setItem(
              //         "smarte2e_smato",
              //         JSON.stringify(this.cs.userSession)
              //       );

              //       this.router.navigate(["/dash/home"]);
              //     });

              //   /* if (this.cs.userSession.user_type == "1") {
              //           this.router.navigate(["/dash"]);
              //       } else {
              //           this.router.navigate(["/dash/seller/orders"]);
              //       }*/
              // }
            } else if (data["status"] == 3) {
              this.form_validation = data["message"];
            } else if (data["status"] == 0) {
              this.form_validation = data["Some Error Occured"];
            } else if (data["status"] == 2) {
              this.form_validation = data["message"];
            }
          },
          (error) => {
          }
        );
    } else {
      this.form_validation = "Fill out Mandatory Fields";
    }
  }
}
